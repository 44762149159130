
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.has-gutter .is-leaf{
        padding-left:40px;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 14px;
        height: 14px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 14px;
    }
    .PromotionOne{
        padding: 0 20px 50px 20px;
        .PromotionOneHeader{
            display: flex;
            flex-direction: column;
            .txt{
                font-size: 18px;
            }
            span{
                font-size: 16px;
                line-height: 1;
            }
            .name{
                margin-top: 30px;
            }
            .txt2{
                margin-top: 30px;
            }
        }
        .PromotionOneTable{
            .discounts{
                cursor: pointer;
                padding: 20px;
                position: relative;
                .addStage{
                    display: flex;
                    align-items: center;
                    color: #1E63F1;
                    position: absolute;
                    right: 30px;
                    top: 0;
                    i{
                        font-size: 16px;
                    }
                    span{
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }
            }
            .table2{
                /deep/.el-table__header-wrapper{
                    display: none;
                }
                .ParticipateBtn{
                    margin-top: 57px;
                }
            }
        }
    }
